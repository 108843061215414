<template>
  <div class="collection" v-if="showId === 'weather'">
    <div class="eq-title">气象采集站</div>
    <div class="eq-lists">
      <div class="eq-items" v-for="t in colDataList" :key="t.id">
        <div class="left-div">
          <img
            class="icon1-img"
            :src="$imgOnlineUrl + '/index/collection/' + t.icon + '.png'"
          />
          <p class="name">{{ t.title }}</p>
        </div>
        <p class="val">{{ t.last_data }}{{ t.data_unit }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showId: {
      type: String,
      default: "",
    },
    weatherCount: {
      type: Number,
      default: 0,
    },
    colDataList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    cameraList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="less" scoped>
.collection {
  width: 282px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top: 6px solid #125397;
  padding: 23px 13px;
  background-color: #0f243d;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 166px;
  left: 78px;
  z-index: 1999;
  transition: all 0.5s;
  p {
    margin: 0 0 10px 0;
    &:first-of-type {
      font-weight: bold;
      font-size: 18px;
      margin: 0 0 20px 0;
    }
  }
  .eq-title {
    color: #3e90e5;
    font-size: 16px;
    margin-bottom: 17px;
    text-align: center;
  }

  .eq-lists {
    display: flex;
    width: 100%;
    flex-direction: column;

    .eq-items {
      width: 100%;
      height: 41px;
      background: rgba(62, 144, 229, 0.25);
      border: 1px solid #3e90e5;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 6px 14px;
      justify-content: space-between;
      margin-bottom: 14px;

      .left-div {
        display: flex;
        align-items: center;
        .icon1-img {
          width: 30px;
          height: 30px;
          margin-right: 16px;
        }
      }
      .name,
      .val {
        font-size: 14px;
        color: #fff;
        font-weight: normal;
      }
      .my-switch {
        /deep/.el-switch__core {
          background-color: #d8e5f0;
        }

        /deep/.el-switch__core:after {
          background-color: #a6aeb5;
        }
      }

      .my-switch.active {
        /deep/.el-switch__core:after {
          background-color: #2878ff;
        }
      }
      p {
        margin: 0;
      }
    }
  }
}
</style>
